export const accreditations = [
	{
		id: 1,
		title: 'Notice of acceptance of the qualification application',
		organization: 'The Saudi Development and Reconstruction Program for Yemen',
		img: '/accreditations/1.png',
	},
	{
		id: 2,
		title:
			'Extends sincere thanks and appreciation for giving us the opportunity of achieving their technical and administrative advancement Of their employees. We value their role in the success of the "Fundamentals of occupational health and safety" program held in collaboration with us.',
		organization: 'Gulf Sigma Consulting and Development',
		img: '/accreditations/2.png',
	},
	{
		id: 3,
		title:
			'A company specializing in mechanical and electrical works, air conditioning systems, alarm systems, fire extinguishing, and construction works. It has completed the work assigned to it successfully, testing and operation of the Abha International Private Hospital project - 300-bed capacity.',
		organization: 'ERAS Technology Group',
		img: '/accreditations/3.png',
	},
	{
		id: 4,
		title:
			'A company specializing in mechanical and electrical works, air conditioning systems, alarm systems, fire extinguishing, and construction works. It has completed the work assigned to it successfully, testing and operation of the Abha International Private Hospital project - 300-bed capacity.',
		organization: 'Taiba Consulting Company',
		img: '/accreditations/4.png',
	},
	{
		id: 5,
		title:
			'They have fully completed the work of implementing the central air conditioning for King Khalid Hospital, Abha within the contractually agreed upon implementation period and demonstrated professionalism during the work. This certificate was issued to them based on their request and without the slightest responsibility.',
		organization: 'Taiba Star Contractors',
		img: '/accreditations/5.png',
	},
	{
		id: 6,
		title:
			'It supplied and installed central air conditioning works in Building No. 16 - Pearl Tower, and has the competence to enable it to carry out similar works.',
		organization: 'Expert Middle East For Contracting',
		img: '/accreditations/6.png',
	},
	// {
	//   id: ,
	//   title: '',
	//   organization: '',
	//   img: '/accreditations/.png',
	// }
];
