import Footer from '../components/Footer';
import { Vmo } from '../components/landing/Vmo';
import { Services } from '../components/landing/Services';
import { Opening } from '../components/landing/Opening';
import { MoreAboutUs } from '../components/landing/MoreAboutUs';
import Clients from '../components/landing/Clients';
import { OpeningMobile } from '../components/landing/Opening-mobile';

export default function Landing() {
	return (
		<div className='min-h-screen w-screen overflow-x-hidden overflow-y-scroll scroll-smooth bg-primary-800 lg:[background:radial-gradient(_89.04%_107.62%_at_87.95%_7.73%,#15513c_0%,#1c295a_28.64%,#1c295a_80%,#03081d_100%_)]'>
			<Opening />
			<OpeningMobile />
			<Clients />
			<Vmo />
			<Services />
			<MoreAboutUs />
			<Footer />
		</div>
	);
}
