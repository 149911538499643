import { useEffect, useState } from 'react';
import Landing from '../../pages/Landing';
import OurServices from './OurServices';
import OurClients from './OurClients';
import ContactUs from './ContactUs';

import { AnimatePresence, motion } from 'framer-motion';
import OurProjects from './OurProjects';
import { Tab } from '../tabs/Tab';
import { useSearchParams } from 'react-router-dom';
import DarkLogo from '../../assets/svgs/logo-dark.png';
import OurAccreditation from './OurAccreditation';
import { MobileNavbar } from './MobileNavbar';

const Logo = () => {
	return (
		<img
			alt='logo'
			src={DarkLogo}
			className='h-14 cursor-pointer'
		/>
	);
};

const DEFAULT_TABS = {
	main: { id: 'main', name: <Logo />, component: Landing },
	home: { id: 'home', name: 'Home', component: Landing },
	projects: { id: 'projects', name: 'Our Projects', component: OurProjects },
	services: { id: 'services', name: 'Our Services', component: OurServices },
	clients: { id: 'clients', name: 'Our Clients', component: OurClients },
	accreditation: {
		id: 'accreditation',
		name: 'Our Accreditation',
		component: OurAccreditation,
	},
	contact: { id: 'contact', name: 'Contact Us', component: ContactUs },
};

function TabsController(props) {
	let [searchParams, setSearchParams] = useSearchParams();
	const tabId = searchParams.get('active') || 'home';
	const { tabs = DEFAULT_TABS } = props;
	const [activeTab, setActiveTab] = useState(tabs[tabId]);

	useEffect(() => setActiveTab(tabs[tabId]), [tabId]);

	const handleTabClick = (currentTab) => {
		if (currentTab.id === activeTab.id) return;
		if (currentTab.id === 'main') return setSearchParams({ active: 'home' });

		setSearchParams({ active: currentTab.id });
	};

	const selectedTab = activeTab.name;
	const ActiveComponent = activeTab.component;

	const availableTabs = Object.keys(tabs)
		.filter((tab) => !(tab === 'main' && selectedTab.toLowerCase() === 'home'))
		.map((tab) => {
			const currentTab = tabs[tab];

			return (
				<Tab
					key={currentTab.id}
					name={currentTab.name}
					isActive={currentTab.id === activeTab.id}
					onClick={() => handleTabClick(currentTab)}
				/>
			);
		});

	return (
		<div className='relative min-h-screen w-screen max-w-[100vw] overflow-hidden px-0 font-poppins'>
			<div className='mx-auto flex w-full max-w-[100vw] items-center justify-between bg-[#f6f6f6]'>
				<MobileNavbar
					activeTab={activeTab}
					handleTabClick={handleTabClick}
				/>
				<motion.header
					layout
					layoutRoot
					className='sticky left-0 right-0 top-0 z-10 mx-auto hidden h-[80px] w-screen max-w-[1185px] items-center justify-evenly gap-2 overflow-hidden rounded-md bg-[#f6f6f6] p-2 text-xl font-medium md:flex'>
					{availableTabs}
				</motion.header>
			</div>

			<AnimatePresence mode='wait'>
				<motion.div
					key={selectedTab || 'empty'}
					initial={{ y: 10, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: -10, opacity: 0 }}
					transition={{ duration: 0.2 }}
					className='flex w-full flex-grow flex-col gap-7'>
					<ActiveComponent />
				</motion.div>
			</AnimatePresence>
		</div>
	);
}

export default TabsController;
