import clsx from 'clsx';
import { useState } from 'react';
import Landing from '../../pages/Landing';
import OurProjects from './OurProjects';
import OurServices from './OurServices';
import OurClients from './OurClients';
import OurAccreditation from './OurAccreditation';
import ContactUs from './ContactUs';
import { MobileTab } from '../tabs/Tab';
import DarkLogo from '../../assets/svgs/logo-dark.png';

const DEFAULT_TABS = {
	// main: { id: 'main', name: <Logo />, component: Landing },
	home: { id: 'home', name: 'Home', component: Landing },
	projects: { id: 'projects', name: 'Our Projects', component: OurProjects },
	services: { id: 'services', name: 'Our Services', component: OurServices },
	clients: { id: 'clients', name: 'Our Clients', component: OurClients },
	accreditation: {
		id: 'accreditation',
		name: 'Our Accreditation',
		component: OurAccreditation,
	},
	contact: { id: 'contact', name: 'Contact Us', component: ContactUs },
};

const Logo = () => {
	return (
		<img
			alt='logo'
			src={DarkLogo}
			className='h-14 cursor-pointer'
		/>
	);
};

export const MobileNavbar = (props) => {
	const { activeTab, handleTabClick } = props;
	const [open, setOpen] = useState(false);
	const toggleOpen = () => setOpen((prev) => !prev);

	return (
		<div className='md:hidden'>
			<div className='flex h-[50px] w-[100vw] items-center justify-between px-3 py-3'>
				{activeTab.id === 'home' ? (
					<div></div>
				) : (
					<button
						onClick={() => {
							handleTabClick(DEFAULT_TABS.home);
							setOpen(false);
						}}>
						<Logo />
					</button>
				)}

				<div className='relative'>
					<nav>
						<button
							className='relative ml-8 h-10 w-10 bg-transparent text-gray-500 focus:outline-none'
							onClick={toggleOpen}>
							<div className='absolute top-1/2 block w-5 -translate-y-1/2 transform'>
								<span
									aria-hidden='true'
									className={clsx(
										open ? 'rotate-45' : '-translate-y-[0.5rem]',
										'absolute block h-0.5 w-8 transform bg-current transition duration-500 ease-in-out'
									)}></span>
								<span
									aria-hidden='true'
									className={clsx(
										open ? 'opacity-0' : '',
										'absolute block h-0.5 w-8 transform bg-current transition duration-500 ease-in-out'
									)}></span>
								<span
									aria-hidden='true'
									className={clsx(
										open ? '-rotate-45' : 'translate-y-[0.5rem]',
										'absolute block h-0.5 w-8 transform bg-current transition duration-500 ease-in-out'
									)}></span>
							</div>
						</button>
					</nav>
				</div>
			</div>

			<div
				className={clsx(
					'absolute left-0 top-[50px] z-20 w-full rounded-b-xl bg-[#f6f6f6] shadow-lg transition-transform duration-500 ease-in-out',
					open ? 'block' : 'hidden'
				)}>
				<ul className='flex flex-col items-center space-y-4 pb-6'>
					{Object.values(DEFAULT_TABS).map((tab) => (
						<MobileTab
							{...tab}
							key={tab.id}
							isActive={tab.id === activeTab.id}
							onClick={() => {
								handleTabClick(tab);
								setOpen(false);
							}}
						/>
					))}
				</ul>
			</div>
		</div>
	);
};
