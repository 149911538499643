import CountUp from 'react-countup';

const StatsCard = (props) => {
	const { title, duration = 2, toNumber } = props;

	return (
		<div className='flex flex-col items-center rounded-md border border-[#ef993d] p-6'>
			<h3 className='text-dark-grey-900 text-center text-5xl font-extrabold leading-tight text-[#ef993d]'>
				<CountUp
					end={toNumber}
					duration={duration}
				/>
				+
			</h3>
			<p className='text-dark-grey-600 text-center text-xl font-medium leading-7 text-white'>
				{title}
			</p>
		</div>
	);
};

export const Achievements = () => {
	return (
		<div className='flex w-full flex-col justify-center gap-10 p-8 md:h-[20vh]'>
			{/* <h3 className='w-fit border-l-8 border-primary-700 py-2 pl-4 pr-20 text-4xl font-semibold text-primary-800 [background:linear-gradient(_90deg,#fff_-5%,rgba(255,255,255,0)_100%_)]'>
				Our Services
			</h3> */}

			<div className='mx-auto w-[80%]'>
				<div className='grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3'>
					<StatsCard
						toNumber={63}
						title='Successful projects'
					/>

					<StatsCard
						toNumber={30}
						title='Satisfied Customers'
					/>

					<StatsCard
						toNumber={10}
						title='Years of Experience'
					/>
				</div>
			</div>
		</div>
	);
};
