import { useState } from 'react';
import Modal from '../Modal';
import { accreditations } from '../../data/accreditations';
import { OurCertificates } from './OurCetificates';

const RightArrow = () => {
	return (
		<svg
			className='h-3.5 w-3.5 rtl:rotate-180'
			aria-hidden='true'
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 14 10'>
			<path
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='2'
				d='M1 5h12m0 0L9 1m4 4L9 9'
			/>
		</svg>
	);
};

const AccreditationCard = (props) => {
	const { title, organization, img } = props;
	const [open, setOpen] = useState(false);

	const handleOpen = (e) => {
		e.preventDefault();
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	return (
		<>
			<div className='accreditation-card-container mx-auto flex h-full max-w-[500px] flex-col text-center'>
				<div className='testimonial flex flex-grow flex-col'>
					<blockquote className='flex flex-grow items-center justify-center'>
						{title}
					</blockquote>
					<span></span>
					<div className='flex w-full items-center justify-between gap-4'>
						<p className='line-clamp-1 flex-grow text-left font-semibold text-primary-800'>
							{organization}
						</p>

						<button
							onClick={(e) => handleOpen(e)}
							className='z-10 flex h-[30px] min-h-[30px] w-[30px] min-w-[30px] items-center justify-center rounded-full bg-primary-800 text-white'>
							<RightArrow />
						</button>
					</div>
				</div>
			</div>

			<Modal
				img={img}
				// open={true}
				open={open}
				onClose={handleClose}
			/>
		</>
	);
};

export default function OurAccreditation() {
	return (
		<div className='mx-auto min-h-[calc(100vh_-_64px)] w-[80%] p-10'>
			<OurCertificates />

			<h3 className='section-title'>Accreditations & Testimonials</h3>

			<div className='my-8 grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 lg:gap-10'>
				{accreditations.map((accreditation, index) => (
					<AccreditationCard
						key={index}
						{...accreditation}
					/>
				))}
			</div>
		</div>
	);
}
