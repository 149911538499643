import { useSearchParams } from 'react-router-dom';
import { projects } from '../../data/projects';
import ArrowDown from '../../assets/svgs/arrow-down';
import ShortArrowDown from '../../assets/svgs/short-arrow-down.svg';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useRef } from 'react';
import clsx from 'clsx';

function Arrow(props) {
	const { className, isRight, style, onClick } = props;

	return (
		<div
			className={clsx(
				className,
				'!inline-flex items-center justify-center rounded-full bg-[#1c295a] before:hidden'
			)}
			style={{ ...style, backgroundColor: '#1c295a', borderRadius: '50%' }}
			onClick={onClick}>
			<img
				className={isRight ? '-rotate-90' : '-ml-[2px] rotate-90'}
				src={ShortArrowDown}
			/>
		</div>
	);
}

const getLogos = (clientImages, clients) => {
	if (Array.isArray(clients)) {
		return (
			<div className='flex items-center justify-center gap-2'>
				{clients.map((client, index) => (
					<div
						key={index}
						className='flex h-14 w-14 items-center justify-center rounded-md border border-[#dadada] bg-white p-1'>
						<img
							width={200}
							height={200}
							src={clientImages[index]}
							alt={client + '-icon'}
							className='h-full w-full rounded-md'
						/>
					</div>
				))}
			</div>
		);
	}

	return (
		<div className='flex h-14 w-14 items-center justify-center rounded-md border border-[#dadada] bg-white p-1'>
			<img
				width={200}
				height={200}
				src={clientImages}
				alt={clients + '-icon'}
				className='h-full w-full rounded-md'
			/>
		</div>
	);
};

export default function SingleProject() {
	let sliderRef = useRef(null);
	let [searchParams, setSearchParams] = useSearchParams();
	const projectId = searchParams.get('id');

	const project = projects[projectId];
	const {
		id,
		tags,
		title,
		client,
		gallery,
		duration,
		contractor,
		clientImage,
		description,
		furtherDescription,
	} = project;

	const settings = {
		dots: true,
		infinite: true,
		speed: 1000,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: <Arrow />,
		nextArrow: <Arrow isRight />,
	};

	return (
		<div className='relative'>
			<div className='mx-auto min-h-[calc(100vh_-_64px)] w-[80%] p-10 font-poppins text-[#1c295a]'>
				<button
					className='absolute left-10 top-12 flex items-center font-medium text-[#1c295a]'
					onClick={() => setSearchParams({ active: 'projects' })}>
					<div className='rotate-90 scale-90'>
						<ArrowDown color='#1c295a' />
					</div>
					<span className='border-b border-b-[#1c295a]'>All projects</span>
				</button>
				<h3 className='section-title'>{title}</h3>

				<div className='slider-container mx-auto mt-12 h-[50vh] w-[60%] max-w-[1000px]'>
					<Slider
						ref={(slider) => (sliderRef = slider)}
						{...settings}>
						{gallery.map((client, index) => (
							<div
								key={index}
								className='!flex w-full items-center justify-center'>
								<img
									key={index}
									src={client}
									className='h-[50vh] max-h-full w-auto'
								/>
							</div>
						))}
					</Slider>
				</div>

				<div className='slider-container mx-auto mt-12 h-[50vh] w-[80%] max-w-[1000px]'>
					<div className='mb-6 flex w-full items-center justify-between gap-5'>
						<div className='flex items-center gap-3'>
							{getLogos(clientImage, client)}
							<span className='text-lg font-semibold'>
								{Array.isArray(client) ? client[0] : client}{' '}
								{`${contractor ? `- ${contractor}` : ''}`}
							</span>
						</div>

						<div className='min-w-fit font-medium'>({duration})</div>
					</div>
					<p className='text-justify'>
						{description}. {furtherDescription}
					</p>
					<div className='mt-6 flex w-full justify-end'>
						{tags.length > 0 && (
							<ul className='flex h-fit max-h-8 w-fit gap-[6px] overflow-x-scroll bg-white'>
								{tags.map((tag, index) => (
									<li
										key={index}
										className='rounded-md bg-[#f0f0f0] px-3 py-1 text-xs uppercase'>
										{tag}
									</li>
								))}
							</ul>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
